import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { getMethod } from "../../services/base_service";
import { Apiurl } from "../../services/apiRoute";
import jsPDF from "jspdf";
import Pagination from "../../services/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { debounce } from "lodash";


const UserCollections = () => {

  const navigate = useNavigate()
  const [collections, setCollections] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currencies, setCurrencies] = useState([])
  const itemsPerPage = 10;
  const [searchComp, setSearchComp] = useState("");
  const [inputTimeout, setInputTimeout] = useState(null);
  const [limit, setLimit] = useState(50);

  const getCollections = async () => {
    setIsLoading(true);
    await getMethod(Apiurl.collection)
      .then((res) => {
        const filtered = res.data.filter((collection) =>
          collection.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setTotalPages(Math.ceil(filtered.length / limit));
        setCollections(res.data);
        setFilteredCollections(filtered);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    setLimit(Number(event.target.value));
  };

  // const handleGeneratePdf = async (id) => {
  //   setIsLoading(true);

  //   await getMethod(`${Apiurl.collection}/collections?user_id=${id}`)
  //     .then((res) => {
  //       const totalCollection = collections.find((collection) => collection.user_id === id);
  //       console.log('totalCollection: ', totalCollection)
  //       const ledgerData = res.data;
  //       console.log('ledgerData:  ', ledgerData)
  //       const pdf = new jsPDF();
  //       pdf.setFont("helvetica", "bold");
  //       pdf.setFontSize(13);
  //       // pdf.setTextColor(0, 0, 0);
  //       pdf.text(`USER LEDGER: ${totalCollection.name}`, 10, 15);
  //       pdf.text(`SUMMARY :`, 10, ledgerData.length * 8 + 41);
  //       const headers = [
  //         "Date",
  //         "Transaction",
  //         "Currency",
  //         "Amount",
  //         "Payment Type",
  //         "Type",
  //       ];
  //       const headerY = 25;
  //       const headerHeight = 7;
  //       const fontSize = 10;

  //       const headerX = 10;
  //       const headerWidth = headers.length * 32;
  //       pdf.setFillColor(200, 200, 200);
  //       pdf.rect(
  //         headerX,
  //         headerY - headerHeight + 1,
  //         headerWidth,
  //         headerHeight,
  //         "F"
  //       );

  //       pdf.setTextColor(0, 0, 0);

  //       headers.forEach((header, index) => {
  //         pdf.setFontSize(fontSize);
  //         pdf.text(header, 30 + index * 30, headerY - 2);
  //       });

  //       const startY = headerY + headerHeight;
  //       const lineX = 10;
  //       const lineY = startY - 5;
  //       const lineHeight = 8;
  //       const lineWidth = headerWidth;
  //       // pdf.rect(lineX, lineY, lineWidth, lineHeight, "S");

  //       pdf.setFontSize(fontSize);
  //       ledgerData.forEach((entry, index) => {
  //         const rowY = startY + index * 8;
  //         const lineY = rowY - 5;
  //         pdf.rect(lineX, lineY, lineWidth, lineHeight, "S");
  //         pdf.text(entry.createdAt.substring(0, 10), 25, rowY);
  //         pdf.text(entry.transaction_type, 25 + 35 * 1, rowY);
  //         pdf.text(entry.currency.name, 20 + 35 * 2, rowY);
  //         pdf.text(atob(entry.amount), 15 + 35 * 3, rowY);
  //         pdf.text(entry.payment_type, 15 + 35 * 4, rowY);
  //         pdf.text(entry.type, 8 + 35 * 5, rowY);
  //       });

  //       totalCollection.currency.forEach((collect, index) => {
  //         const rowY = startY + ledgerData.length * 8 + 15 + index * 8;
  //         // pdf.setFillColor(300, 200, 100);
  //         pdf.setTextColor(0, 0, 0);
  //         pdf.text(`CURRENCY : ${collect.currency_names}`, 10, rowY);
  //         pdf.setTextColor(0, 128, 0);
  //         pdf.text(`TOTAL WITHDRAWAL : ${collect.totalDr}`, 50, rowY);
  //         pdf.setTextColor(255, 0, 0);
  //         pdf.text(`TOTAL CREDITED : ${collect.totalCr}`, 110, rowY);
  //         pdf.setTextColor(0, 0, 255);
  //         pdf.text(`NET AMOUNT : ${collect.totalAmount}`, 160, rowY);
  //       });

  //       pdf.save("user_ledger.pdf");
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log('error: ', error)
  //       setIsLoading(false);
  //     });
  // };

  const handleGeneratePdf = async (id) => {
    setIsLoading(true);

    navigate(
      "/user-collections-list",
      {
        state: { user_id: id }
      }
    )
    // await getMethod(`${Apiurl.collection}/collections?user_id=${id}`)
    //   .then((res) => {
    //     const totalCollection = collections.find((collection) => collection.user_id === id);
    //     const ledgerData = res.data;
    //     const pdf = new jsPDF();
    //     pdf.setFont("helvetica", "bold");
    //     pdf.setFontSize(13);

    //     const addLedgerData = (data, startY) => {
    //       pdf.setFontSize(10);
    //       data.forEach((entry, index) => {
    //         const rowY = startY + index * 8;
    //         const lineY = rowY - 5;
    //         const lineX = 10;
    //         pdf.rect(lineX, lineY, lineWidth, lineHeight, "S");
    //         pdf.text(entry.createdAt.substring(0, 10), 25, rowY);
    //         pdf.text(entry.transaction_type, 25 + 35 * 1, rowY);
    //         pdf.text(entry.currency.name, 20 + 35 * 2, rowY);
    //         pdf.text(atob(entry.amount), 15 + 35 * 3, rowY);
    //         pdf.text(entry.payment_type, 15 + 35 * 4, rowY);
    //         pdf.text(entry.type, 8 + 35 * 5, rowY);
    //       });
    //     };

    //     pdf.text(`USER LEDGER: ${totalCollection.name}`, 10, 15);

    //     let entriesPerPage = 30;
    //     let currentPage = 1;
    //     let startY = 35;
    //     const headers = [
    //       "Date",
    //       "Transaction",
    //       "Currency",
    //       "Amount",
    //       "Payment Type",
    //       "Type",
    //     ];
    //     const headerY = 25;
    //     const headerHeight = 7;
    //     const fontSize = 10;

    //     const lineHeight = 8;

    //     const headerX = 10;
    //     const headerWidth = headers.length * 32;
    //     const lineWidth = headerWidth;
    //     pdf.setFillColor(200, 200, 200);
    //     pdf.rect(headerX, headerY - headerHeight + 1, headerWidth, headerHeight, "F");

    //     headers.forEach((header, index) => {
    //       pdf.setFontSize(fontSize);
    //       pdf.setTextColor(0, 0, 0);
    //       pdf.text(header, 30 + index * 30, headerY - 1);
    //     });

    //     addLedgerData(ledgerData.slice(0, entriesPerPage), startY);

    //     for (let i = entriesPerPage; i < ledgerData.length; i += entriesPerPage) {
    //       pdf.addPage();
    //       currentPage++;
    //       startY = headerY + headerHeight;

    //       pdf.text(`USER LEDGER (Page ${currentPage})`, 10, 15);
    //       pdf.setFillColor(200, 200, 200);
    //       pdf.rect(headerX, headerY - headerHeight + 1, headerWidth, headerHeight, "F");
    //       headers.forEach((header, index) => {
    //         pdf.setFontSize(fontSize);
    //         pdf.text(header, 30 + index * 30, headerY - 2);
    //       });

    //       addLedgerData(ledgerData.slice(i, i + entriesPerPage), startY);
    //     }
    //     const lastRemaining = ledgerData.length % entriesPerPage;
    //     console.log('lastRemaining: ', lastRemaining)
    //     const summaryY = startY + lastRemaining * 7 + 30;
    //     pdf.text(`SUMMARY :`, 10, summaryY - 10);

    //     totalCollection.currency.forEach((collect, index) => {
    //       // const rowY = startY + ledgerData.length * 8 + 15 + index * 8;
    //       const rowY = summaryY + index * 8;
    //       // pdf.setFillColor(300, 200, 100);
    //       pdf.setFontSize(9);
    //       pdf.setTextColor(0, 0, 0);
    //       pdf.text(`CURRENCY : ${collect.currency_names}`, 10, rowY);
    //       pdf.setTextColor(0, 128, 0);
    //       pdf.text(`TOTAL WITHDRAWAL : ${collect.totalDr.toFixed(2)}`, 45, rowY);
    //       pdf.setTextColor(255, 0, 0);
    //       pdf.text(`TOTAL CREDITED : ${collect.totalCr.toFixed(2)}`, 105, rowY);
    //       pdf.setTextColor(0, 0, 255);
    //       pdf.text(`NET AMOUNT : ${collect.totalAmount.toFixed(2)}`, 160, rowY);
    //     });

    //     pdf.save(`Ledger_Report_for_${totalCollection.name}.pdf`);
    //     setIsLoading(false);
    //   })
    //   .catch((error) => {
    //     console.log('error: ', error)
    //     setIsLoading(false);
    //   });
  };


  const slicedCollections = filteredCollections.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // const handleSearch = debounce(() => {
  //   const filtered = collections.filter((collection) =>
  //     collection.name.toLowerCase().includes(searchQuery.toLowerCase())
  //   );
  //   setFilteredCollections(filtered);
  //   const newTotalPages = Math.ceil(filtered.length / itemsPerPage);
  //   setTotalPages(newTotalPages);
  //   setCurrentPage(1);
  // }, 300);

  const getCurrencies = async () => {
    await getMethod(`${Apiurl.currency}/list?checkForPermission=false`).then((data) => {
      setCurrencies(data.data.currency)
    }).catch((error) => {
    })
  }

  useEffect(() => {
    getCurrencies()
  }, [])

  useEffect(() => {
    getCollections();
  }, [searchQuery, limit]);

  const handleSearch = (search) => {
    setSearchComp(search);
    if (inputTimeout) clearTimeout(inputTimeout);
    setInputTimeout(
      setTimeout(() => {
        setSearchQuery(search);
        setCurrentPage(1);
      }, 500)
    );
  };


  return (
    <div>
      <Navbar />

      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>User Collections</h1>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                  <div className="mb-3 mb-xl-0">
                    <Link to="/transfer">
                      <span className="btn btn-dark">Add Transfer</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">

                  <div className="input-group">
                    <input
                      type="text"
                      name="search"
                      onChange={(e) => {
                        handleSearch(e.target.value);
                      }}
                      value={searchComp}
                      className="form-control"
                      placeholder="Search here..."
                    />
                    <div className="input-group-append">
                      <span className="input-group-text" id="search-mail">
                        <i className="fa fa-search text-info"></i>
                      </span>
                    </div>
                  </div>

                </div>
              </div>
              {isLoading ? (
                <span className="loader"></span>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>User Name</th>
                          {currencies.map((item) => (
                            <th key={item.id}>{item.name}</th>
                          ))}
                          <th>View Full List</th>
                        </tr>
                      </thead>

                      <tbody>
                        {slicedCollections && slicedCollections.length > 0 ? (
                          slicedCollections.map((list, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>#{(currentPage - 1) * itemsPerPage + 1 + i}</td>
                                <td>{list.name}</td>
                                {currencies.map((currency) => (
                                  <td key={currency.id}>
                                    {list.currency.find((userCurr) => userCurr.currency_names === currency.name)?.totalAmount.toFixed(2) || '-'}
                                  </td>
                                ))}
                                <td>
                                  {/* <button
                                    type="button"
                                    className="btn btn-default btn-sm edit-buton"
                                    title="Download"
                                    onClick={() =>
                                      handleGeneratePdf(list.user_id)
                                    }
                                  >
                                    <i className="fa fa-download"></i>
                                  </button> */}
                                  <button
                                    type="button"
                                    className="btn btn-default btn-sm"
                                    title="View List"
                                    onClick={() =>
                                      handleGeneratePdf(list.user_id)
                                    }
                                  >
                                    <i className="fa fa-info"></i>
                                  </button>
                                </td>
                              </tr>
                            </React.Fragment>

                          ))) : (
                          <tr>
                            <td style={{ borderTop: "none", textAlign: "center" }} colSpan={7}>No records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {slicedCollections.length > 0 &&
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                      limit={limit}
                      onLimitChange={handleItemsPerPageChange}
                    />
                  }
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCollections;
